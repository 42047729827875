import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommunityModel } from '@/models/community-model'
import { apiService } from '@/services/api-services'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class CommunityViewModel {
  @observable myCommunities?: CommunityModel[] = []

  constructor() {
    this.fetchData()
  }

  @asyncAction *fetchData() {
    try {
      this.myCommunities = yield apiService.daos.fetchDaos()
    } catch (e) {
      snackController.commonError('Something went wrong when fetching community')
    }
  }
}
