


























import GroupCover from '@/modules/community/common/components/group-cover.vue'
import { CommunityViewModel } from '@/modules/community/viewmodels/community-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    GroupCover,
  },
})
export default class MyCommunityBoard extends Vue {
  @Provide() vm = new CommunityViewModel()

  communities = [
    {
      name: '#ART',
      code: '1234',
      postNumber: 124,
      followerNumber: 1234,
    },
    {
      name: '#REACTION',
      code: 'F134',
      postNumber: 1234,
      followerNumber: 324,
    },
  ]
}
